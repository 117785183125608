import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import useCertificateSC from "../hooks/useCertificateSc";
import { fireSwal, fireSwalObj } from "../utils/alert";
import Loading from "./loading.component";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DocumentViewer = () => {
  const [validCertificate, setValidCertificate] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id, hash } = useParams();
  const [pdfBlob, setPdfBlob] = useState(null);
  const { getDocument } = useCertificateSC();
  const viewer = useRef(null);
  const [signers, setSigners] = useState([]);
  const init = async () => {
    const url = window.location.href.split("/");
    const hash = url[4];
    const key = url[5];
    if (hash == null || key == null || hash.length !== 8 || key.length !== 8) {
      fireSwal(
        "error",
        "Unknown Certificate",
        "The certificate is not signed by Imdaat"
      );
      setIsLoading(false);
    } else {
      const data = { hash: hash, key: key };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_FRONTEND_IP}external-verify/`,
          data,
          {
            responseType: "blob",
          }
        );
        setPdfBlob(res.data);
        setValidCertificate(true);
        setIsLoading(false);
      } catch (error) {
        if (error && error.response && error.response.status == 404) {
          fireSwalObj(
            {
              icon: "error",
              title: "Unknown Certificate",
              text: "The certificate is not signed by Imdaat",
            },
            navigate("/")
          );
        } else {
          fireSwalObj(
            {
              icon: "error",
              title: "Unexpected Error",
              text: "Please try again later",
            },
            navigate("/")
          );
        }
        setIsLoading(false);
      }
    }

    const certificateData = await getDocument(hash);
    setSigners(certificateData.signers);
  };

  useEffect(() => {
    init();
  }, [id]);

  useEffect(() => {
    if (pdfBlob && viewer.current) {
      WebViewer(
        {
          path: "/webviewer/",
          initialDoc: null,
          licenseKey: "R5Z9U8lpvYFVdrqSe7Jf",
        },
        viewer.current
      ).then((instance) => {
        instance.UI.loadDocument(pdfBlob, {
          filename: "document.pdf",
        });
        instance.UI.setHeaderItems((header) => {
          instance.UI.disableFeatures(instance.Feature.Download);
          instance.UI.disableFeatures(instance.Feature.Search);
          instance.UI.disableElements(["panToolButton"]);
          instance.UI.disableElements(["selectToolButton"]);
          instance.UI.setHeaderItems((header) => {
            header.getHeader("default").push({
              img: "icon-header-download",
              title: "Download",
              index: -1,
              type: "actionButton",
              element: "downloadButton",
              onClick: () => {
                instance.UI.downloadPdf();
              },
            });
          });
        });
      });
    }
  }, [pdfBlob]);

  if (!validCertificate) {
    return <Loading isLoading={isLoading} />;
  } else {
    return (
      <>
        <div className="web-viewer-container">
          <div className="webviewer" ref={viewer}>
            <h4 className="web-viewer-title">Document</h4>
          </div>
          <div className="webviewer">
            <h4 className="web-viewer-title">Signed By</h4>
            {signers.map((signer, index) => {
              return (
                <div className="user-profile">
                  {!signer.signature_type || signer.signature_type === "N" ? (
                    <></>
                  ) : (
                    <div className="tape">True Copy</div>
                  )}
                  <div className="user-details">
                    <h2 className="user-name">
                      {signer.name}{" "}
                      <i
                        className="bi bi-patch-check-fill"
                        style={{ color: "#0f6ae1" }}
                      ></i>
                    </h2>
                    <p className="user-position">{signer.position}</p>
                    <p className="user-position">{signer.organization}</p>
                    <p className="user-email">{signer.email}</p>
                    <p className="user-email">Signed at: {signer.signed_at}</p>
                    <p className="user-email">
                      Location:{" "}
                      {signer.location.city + " - " + signer.location.country}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Loading isLoading={isLoading} />;
      </>
    );
  }
};

export default DocumentViewer;
