import React, { Component } from "react";
import GLightbox from "glightbox";
export default class Home extends Component {
  componentDidMount() {
    GLightbox({
      selector: ".glightbox",
    });
  }

  handleBuyNow = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_ADMIN_DOMAIN}/register`;
  };

  render() {
    return (
      <>
        <section id="hero" className="d-flex align-items-center">
          <div className="container" data-aos="zoom-out" data-aos-delay="100">
            <h1>
              Welcome to <span>Imda@</span>
            </h1>
            <h2>
              A Decentralized Platform for Digital Signature Powered by
              Blockchain
            </h2>
            <div className="d-flex">
              <a
                href="https://admin.imdaat.com/register"
                className="btn-get-started"
              >
                Register your Company
              </a>
              <a
                // href="https://imdaat.com/imdaat_video.mp4"
                href="https://www.youtube.com/watch?v=9Sc9pDrhLlE"
                className="glightbox btn-watch-video"
              >
                <i className="bi bi-play-circle"></i>
                <span>Watch Video</span>
              </a>
            </div>
          </div>
        </section>
        <section
          id="featured-services"
          class="featured-services"
          style={{ backgroundColor: "white" }}
        >
          <div class="container" data-aos="fade-up">
            <div class="row">
              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                  <div class="icon">
                    <i class="bx bxl-dribbble"></i>
                  </div>
                  <h4 class="title">Blockchain-Based</h4>
                  <p class="description">
                    Enhancing documents integrity with blockchain-powered
                    decentralization, ensuring tamper-proof and secure
                    authentication.
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                  <div class="icon">
                    <i class="bx bx-network-chart"></i>
                  </div>
                  <h4 class="title">Distributed Storage</h4>
                  <p class="description">
                    Leveraging distributed storage for robust and reliable
                    preservation of documents, guaranteeing high availability
                    and data protection.
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                  <div class="icon">
                    <i class="bx bx-mobile-alt"></i>
                  </div>
                  <h4 class="title">Sign Anywhere</h4>
                  <p class="description">
                    Enabling convenient and secure signing of documents anywhere
                    using the mobile app, providing flexibility and ease of use.
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                  <div class="icon">
                    <i class="bx bx-qr"></i>
                  </div>
                  <h4 class="title">Easy to Verify</h4>
                  <p class="description">
                    Simplifying document verification with QR codes, allowing
                    quick and effortless authentication from any location.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" class="pricing">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Pricing</h2>
              <h3>
                Check our <span>Pricing</span>
              </h3>
              <p>
                Catch the Perfect Deal: Unbeatable Imdaat Packages at Unmatched
                Prices! <br />
              </p>
            </div>

            <div class="row">
              <div
                class="col-lg-3 col-md-6 mt-4 mt-lg-0"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="box featured">
                  <h3>Free Package</h3>
                  <h4>
                    <sup>QAR</sup>0<span></span>
                  </h4>
                  <ul>
                    <li>Explore Our Services at No Cost</li>
                    <li>
                      <b>10 Files</b>
                    </li>
                    <li>
                      <b>20 MB of Storage</b>
                    </li>
                  </ul>
                  <div class="btn-wrap">
                    <a
                      href="#"
                      class="btn-buy"
                      data-package="200"
                      onClick={this.handleBuyNow}
                    >
                      Get Now
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="box">
                  <h3>Standard Package</h3>
                  <h4>
                    <sup>QAR</sup>250<span> </span>
                  </h4>
                  <ul>
                    <li> Start Smart with Our Standard Package</li>
                    <li>
                      <b>50 Files</b>
                    </li>
                    <li>
                      <b>100 MB of Storage</b>
                    </li>
                  </ul>
                  <div class="btn-wrap">
                    <a
                      href="#"
                      data-package="20"
                      class="btn-buy"
                      onClick={this.handleBuyNow}
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 mt-4 mt-md-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="box ">
                  <h3>Premium Package</h3>
                  <h4>
                    <sup>QAR</sup>800<span></span>
                  </h4>
                  <ul>
                    <li>Elevate Your Standards</li>
                    <li>
                      <b>200 Files</b>
                    </li>
                    <li>
                      <b>400 MB of Storage</b>
                    </li>
                  </ul>
                  <div class="btn-wrap">
                    <a
                      href="#"
                      class="btn-buy"
                      data-package="50"
                      onClick={this.handleBuyNow}
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 mt-4 mt-lg-0"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="box">
                  <span class="advanced">Best</span>
                  <h3>Enterprise Package</h3>
                  <h4>
                    <sup>QAR</sup>3,000<span></span>
                  </h4>
                  <ul>
                    <li>Reach the Apex with this Package</li>
                    <li>
                      <b>1,000 Files</b>
                    </li>
                    <li>
                      <b>2 GB of Storage</b>
                    </li>
                  </ul>
                  <div class="btn-wrap">
                    <a
                      href="#"
                      class="btn-buy"
                      data-package="500"
                      onClick={this.handleBuyNow}
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="contact"
          style={{ backgroundColor: "white" }}
        >
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <h3>
                <span>Contact Us</span>
              </h3>
              <p>
                A Decentralized Platform for Digital Signature Powered by
                Blockchain
              </p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>Doha, Qatar</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@genesistechnologies.tech</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>+974 4403-7778</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
