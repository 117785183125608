import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import DocumentViewer from "./components/document_viewer.component";
import Home from "./components/home.component";

function App() {
  const year = new Date().getFullYear();
  return (
    <Router>
      <main id="main">
        <section id="topbar" className="d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:info@genesistechnologies.tech">
                  info@genesistechnologies.tech
                </a>
              </i>
              <i className="bi bi-phone d-flex align-items-center ms-4">
                <span>+974 4403-7778</span>
              </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <a
                href="https://twitter.com/MaxyaBlockchain"
                target="_blank"
                className="twitter"
              >
                <i className="bi bi-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/maxyablockchain"
                target="_blank"
                className="instagram"
              >
                <i className="bi bi-instagram"></i>
              </a>
              {/* <a href="#" className="facebook">
                <i className="bi bi-facebook"></i>
              </a> */}
              {/* <a href="#" className="instagram">
                <i className="bi bi-instagram"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/company/genesistechnology"
                className="linkedin"
                target="_blank"
              >
                <i className="bi bi-linkedin"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCcjWgLCaIn1nQyDNIDeFkGw"
                className="youtube"
                target="_blank"
              >
                <i className="bi bi-youtube"></i>
              </a>
            </div>
          </div>
        </section>
        <header id="header" className="d-flex align-items-center">
          <div className="container d-flex align-items-center justify-content-between">
            <h1 className="logo">
              <Link className="nav-link" to={"/"}>
                <img
                  src="/logo.png"
                  style={{ zoom: 2 }}
                  alt="Genesis Technologies"
                ></img>
              </Link>
            </h1>
            <nav id="navbar" className="navbar">
              <ul>
                {/* <li>
                  <Link className="nav-link" to="/">
                    {"Home"}
                  </Link>
                </li> */}
                <li>
                  <a
                    className="nav-link"
                    href={process.env.REACT_APP_ADMIN_DOMAIN + "/login"}
                  >
                    Login
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/v/:hash/:key" element={<DocumentViewer />} />
        </Routes>
      </main>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex" style={{ marginTop: "15px" }}>
              <a
                href="https://apps.apple.com/us/app/imdaat-for-enterprise/id6477912034"
                target="_blank"
              >
                <img
                  src="/assets/img/apple.png"
                  alt="Download on the App Store"
                  style={{ height: "40px", marginRight: "10px" }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.imdaatforenterprise"
                target="_blank"
              >
                <img
                  src="/assets/img/google.png"
                  alt="Get it on Google Play"
                  style={{ height: "40px" }}
                />
              </a>
            </div>
            <div className="col-md-8" style={{ marginTop: "20px" }}>
              <div className="copyright">
                {year} &copy; Copyright
                <strong>
                  <span>&nbsp; Imda@</span>
                </strong>
                . All Rights Reserved
              </div>
              <div className="credits">
                Developed by{" "}
                <a href="http://genesistechnologies.tech/" target="_blank">
                  Genesis Technologies
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <footer id="footer">
        <div class="container py-4">
          <div class="copyright">
            {year} &copy; Copyright
            <strong>
              <span>&nbsp; Imda@</span>
            </strong>
            . All Rights Reserved
          </div>
          <div class="credits">
            Developed by{" "}
            <a href="http://genesistechnologies.tech/" target="_blank">
              Genesis Technologies
            </a>
          </div>
        </div>
      </footer> */}
    </Router>
  );
}
export default App;
