import { Blocks } from "react-loader-spinner";

const Loading = ({ isLoading }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        position: "fixed",
        justifyContent: "center",
        display: isLoading ? "flex" : "none",
      }}
    >
      <div
        style={{
          top: "50%",
          bottom: "50%",
          position: "absolute",
        }}
      >
        <Blocks
          visible={isLoading}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
        />
      </div>
    </div>
  );
};

export default Loading;
