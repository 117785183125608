import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Main from "./main";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Main></Main>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
