import axios from "axios";

const useCertificateSC = () => {
  const getDocument = async (certId) => {
    const result = await axios.get(
      `${process.env.REACT_APP_FRONTEND_IP}document?id=${certId}`
    );
    return result.data.data;
  };

  return {
    getDocument,
  };
};

export default useCertificateSC;
